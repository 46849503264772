const messages = {
  pt: {
    translations: {
      login: {
        title1: 'Plataforma de Ensino - FABET',
        title2: 'Efetue seu login',
        placeholder_user: 'Usuário',
        placeholder_password: 'Senha',
        button: 'Entrar',
        popover_user: 'Usuário obrigatório.',
        popover_password: 'Senha obrigatória.',
        error_title: 'Falha na autenticação.',
        error_message:
          'Houve um erro na autenticação, verifique suas credenciais.',
      },
      menu: {
        welcome: 'Bem vindo,',
        courses: 'Meus Cursos',
        logout: 'Sair da Plataforma',
        modules: 'Módulos do Curso Atual',
        videos: 'Vídeos',
        complementary_materials: 'Material Complementar',
        exam: 'Avaliações',
        forum: 'Fórum',
      },
      footer: {
        text: 'Todos os direitos reservados',
      },
      errors: {
        error:
          'Ocorreu um erro ao carregar as informações, verifique sua conexão com a internet.',
        watchedVideo: 'Ocorreu um erro ao salvar o vídeo como assistido.',
      },
      loading: 'Carregando',
      dashboard: {
        courses: 'Meus Cursos',
        no_courses: 'Não há nenhum curso disponível no momento.',
        watched_videos: 'de vídeos assistidos.',
        button: 'Acessar',
      },
      module: {
        back_to_courses: 'Voltar para MEUS CURSOS',
        button: 'Acessar Módulo',
        teacher: 'Professor:',
        watched_videos: 'de vídeos assistidos.',
      },
      videos: {
        select_video: 'Selecione um vídeo para iniciar.',
        title: 'Vídeos',
        message: 'Clique no nome do vídeo para reproduzir.',
        no_content: 'Para mais opções, acesse o menu acima.',
        quiz_title: 'Dominando o Tema!',
        next: 'Próxima pergunta',
        score: 'Você acertou',
        question: 'questão.',
        questions: 'questões.',
        congratulations: 'Parabéns! Você dominou o tema!',
        congratulations1:
          'Você não acertou todas as questões! Estude um pouco mais!',
        congratulations2: 'Você errou todas as questões! Estude mais!',
        close:
          'Para fechar, clique no X que encontra-se no canto superior direito desta tela.',
      },
      complementary_materials: {
        title: 'Materiais Complementares',
        text: 'Clique no nome do material para visualizar.',
      },
      exam: {
        status: 'Avaliação não realizada.',
        start: 'Iniciar Avaliação.',
        block: 'Avaliação bloqueada. Assista todos os vídeos para liberar.',
        finish: 'Avaliação Realizada.',
        button_videos: 'Acessar vídeos.',
      },
      exam_questions: {
        button: 'Enviar.',
      },
      foro: {
        title: 'Fórum de Discussões',
        search_button: 'Buscar',
        search_placeholder: 'Digite um termo para buscar ...',
        new_topic_button: 'Novo Tópico',
        no_topics:
          'Ainda não existe nenhum tópico, fique a vontade e crie o primeiro!',
        success: 'Tópico criado com sucesso!',
        placeholder_title: 'Título do tópico',
        placeholder_tags: 'Selecione uma tag que correspnda ao seu tópico.',
        placeholder_message: 'Digite a mensagem referente ao seu tópico.',
        creating: 'Criando tópico',
        create_button: 'Criar tópico',
      },
    },
  },
};

export { messages };
