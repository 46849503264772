import styled from 'styled-components';

export const Label = styled.label`

  input {
    width: 16px;
    height: 16px;

    border: 2px solid #999;
    transition: 0.2s all linear;
    margin-right: 10px;
  }

`
