import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { FiSearch, FiLoader, FiTag, FiMessageCircle } from 'react-icons/fi';
import { MdTitle } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { i18n } from '../../translate/i18n';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import getValidationErrors from '../../utils/getValidationErrors';

import Menu from '../../components/Menu';
import HeaderInfo from '../../components/HeaderInfo';
import Footer from '../../components/Footer';
import Input from '../../components/Input';
import NormalButton from '../../components/NormalButton';
import Select from '../../components/Select';
import TextArea from '../../components/TextArea';

import { Container, SearchForm, Topics, Topic, Modal } from './styles';

interface StudentParams {
  id: number;
  name: string;
}

interface TagParams {
  id: number;
  label: string;
}

interface TopicParams {
  id: number;
  title: string;
  text: string;
  updated_at: string;
  student: StudentParams;
  tag: TagParams;
}

interface TopicData {
  title: string;
  text: string;
  tag_id: number;
  forum_id: any;
  module_id: number;
  course_id: number;
  student_id: number;
}

interface ModuleIdParams {
  module_id: string;
  course_id: string;
}

interface SearchData {
  search: string;
}

const Forum: React.FC = () => {
  const [topics, setTopics] = useState<TopicParams[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showNewTopic, setShowNewTopic] = useState(false);
  const [forum_id, setForumId] = useState();
  const [tags, setTags] = useState<TagParams[]>([]);

  const { student } = useAuth();
  const { addToast } = useToast();
  const { params } = useRouteMatch<ModuleIdParams>();
  const history = useHistory();

  const formRef = useRef<FormHandles>(null);

  const course_info = {
    course_id: params.course_id,
    module_id: params.module_id,
  };

  const getTopics = useCallback(async () => {
    try {
      setIsLoading(true);
      await api.get(`/forum/topics/${params.module_id}`).then(response => {
        const topic = response.data;

        setTopics(topic);
      });

      await api
        .get(`/forum/id/${params.course_id}/${params.module_id}/${student.id}`)
        .then(response => {
          const forum = response.data.id;

          setForumId(forum);
        });

      setIsLoading(false);
    } catch (err) {
      setIsLoading(true);
      addToast({
        type: 'error',
        title: i18n.t('errors.error'),
      });
      setIsLoading(false);

      history.push(`/dashboard`);
    }
  }, [addToast, params.module_id, params.course_id, student.id, history]);

  const handleSearch = useCallback(
    async (data: SearchData) => {
      try {
        setIsLoading(true);

        await api
          .get(`/forum/topics/${params.module_id}?search=${data.search}`)
          .then(response => {
            const topic = response.data;
            setTopics(topic);
          });
        setIsLoading(false);
      } catch (err) {
        setIsLoading(true);
        addToast({
          type: 'error',
          title: i18n.t('errors.error'),
        });
        setIsLoading(false);
      }
    },
    [addToast, params.module_id],
  );

  const getTags = useCallback(
    async id => {
      try {
        await api.get(`/forum/${id}/tags`).then(response => {
          const topic_tags = response.data;
          setTags(topic_tags);
        });
      } catch (err) {
        addToast({
          type: 'error',
          title:
            'Ocorreu um erro ao carregar, verifique sua conexão com a internet.',
        });
      }
    },
    [addToast],
  );

  const handleOpenNewTopic = useCallback(() => {
    getTags(forum_id);
    setShowNewTopic(true);
  }, [getTags, forum_id]);

  const handleCloseNewTopic = () => setShowNewTopic(false);

  const handleAddTopic = useCallback(
    async (data: TopicData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('Campo obrigatório!'),
          text: Yup.string().required('Campo Obrigatório'),
          tag_id: Yup.string().required('Seleciona ao menos uma tag'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setIsLoading(true);

        data.forum_id = forum_id;
        data.course_id = parseInt(params.course_id, 10);
        data.module_id = parseInt(params.module_id, 10);
        data.student_id = parseInt(student.id, 10);

        await api.post('/forum/topic/', data);

        addToast({
          type: 'success',
          title: i18n.t('foro.success'),
        });

        getTopics();

        setShowNewTopic(false);
        setIsLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          type: 'error',
          title: 'Erro ao criar tópico.',
          description: i18n.t('errors.error'),
        });
        setIsLoading(false);
      }
    },
    [
      addToast,
      forum_id,
      params.course_id,
      params.module_id,
      student.id,
      getTopics,
    ],
  );

  useEffect(() => {
    getTopics();
  }, [getTopics]);

  return (
    <>
      <Menu course_info={course_info} />
      <HeaderInfo course_info={course_info} />
      <Container>
        <h4>{i18n.t('foro.title')}</h4>

        <SearchForm>
          <Form ref={formRef} onSubmit={handleSearch}>
            <Input
              name="search"
              placeholder={i18n.t('foro.search_placeholder')}
              icon={FiSearch}
              hidden={false}
            />
            <NormalButton disabled={isLoading} type="submit">
              {isLoading ? (
                <FiLoader size={20} />
              ) : (
                i18n.t('foro.search_button')
              )}
            </NormalButton>
          </Form>
        </SearchForm>
        {topics.length ? (
          <>
            <NormalButton type="button" onClick={handleOpenNewTopic}>
              {i18n.t('foro.new_topic_button')}
            </NormalButton>

            <Topics>
              {topics.map(topic => (
                <Link
                  to={`/topic/${topic.id}/forum/${forum_id}/module/${params.module_id}/course/${params.course_id}`}
                >
                  <Topic>
                    <img
                      src={`https://ui-avatars.com/api/?name=${topic.student.name}`}
                      alt="avatar"
                    />
                    <div>
                      <div>
                        <span>{topic.tag.label}</span>
                        <h6>{topic.title}</h6>
                      </div>
                      <span>
                        {topic.student.name} <time>{topic.updated_at}</time>
                      </span>
                    </div>
                  </Topic>
                </Link>
              ))}
            </Topics>
          </>
        ) : (
          <div>
            <h5>{i18n.t('foro.no_topics')}</h5>
            <NormalButton type="button" onClick={handleOpenNewTopic}>
              {i18n.t('foro.new_topic_button')}
            </NormalButton>
          </div>
        )}
      </Container>

      <Modal show={showNewTopic} onHide={handleCloseNewTopic}>
        <Modal.Header closeButton>
          <Modal.Title>{i18n.t('foro.new_topic_button')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRef} onSubmit={handleAddTopic}>
            <Input
              name="title"
              placeholder={i18n.t('foro.placeholder_title')}
              icon={MdTitle}
              hidden={false}
            />

            <Select
              name="tag_id"
              options={tags}
              className="react-select-container"
              classNamePrefix="react-select"
              placeholder={i18n.t('foro.placeholder_tags')}
              icon={FiTag}
            />

            <TextArea
              name="text"
              placeholder={i18n.t('foro.placeholder_message')}
              icon={FiMessageCircle}
            />

            <NormalButton disabled={isLoading} type="submit">
              {isLoading ? (
                <>
                  <FiLoader size={20} /> <span>{i18n.t('foro.creating')}</span>
                </>
              ) : (
                i18n.t('foro.create_button')
              )}
            </NormalButton>
          </Form>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
};

export default Forum;
