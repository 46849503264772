/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

import { Container as ContainerStyled } from 'react-bootstrap';

export const Container = styled(ContainerStyled)`
  padding-left: 0;

  .number {
    background: #6c757d;
    padding: 5px;
    width: 105px;
    border-radius: 10px;
    color: white;
    font-size: 14px;
  }
`;

type ButtonWrapperProps = {
  correct: boolean;
  userClicked: boolean;
};

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  transition: all 0.3s ease;
  :hover {
    opacity: 0.8;
  }

  button {
    cursor: pointer;
    user-select: none;
    text-align: left;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 0;
    padding: 5px 12px;
    color: #fff;
    font-weight: 400;
    transition: 0.2s;

    svg {
      color: #333;
      margin-right: 8px;
      animation: spin 2s linear infinite;
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    background: ${({ correct, userClicked }) =>
      correct ? '#1e7e34' : !correct && userClicked ? '#dc3545' : '#e3e3e3'};

    color: ${({ correct, userClicked }) =>
      correct ? '#fff' : !correct && userClicked ? '#fff' : '#333'};
  }
`;
