import React, { useCallback, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FiLoader, FiCheckCircle } from 'react-icons/fi';
import Vimeo from '@u-wave/react-vimeo';

import { useAuth } from '../../hooks/auth';
import { useVideos } from '../../hooks/Videos';
import { i18n } from '../../translate/i18n';

import Menu from '../../components/Menu';
import HeaderInfo from '../../components/HeaderInfo';
import Footer from '../../components/Footer';
import Quiz from '../../components/Quiz';

import { Container, VideosContainer, Modal } from './styles';

interface ModuleIdParams {
  module_id: string;
  course_id: string;
}

const Videos: React.FC = () => {
  const {
    getVideos,
    videos,
    loadingVideos,
    checkWatchedVideo,
    showVideo,
    showQuiz,
    setShowQuiz,
    isLoadingWatchedInfo,
    playingVideo,
  } = useVideos();
  const { student } = useAuth();
  const { params } = useRouteMatch<ModuleIdParams>();

  const course_info = {
    course_id: params.course_id,
    module_id: params.module_id,
    goTo: 'Materiais Complementares',
    url: `/complementary_materials/course/${params.course_id}/module/${params.module_id}/`,
  };

  const handleCheckWatchedVideo = useCallback(() => {
    console.log('teste');
    checkWatchedVideo(params.course_id, params.module_id);
  }, [checkWatchedVideo, params.course_id, params.module_id]);

  useEffect(() => {
    getVideos(params.course_id, params.module_id);
  }, [getVideos, params.course_id, params.module_id]);

  return (
    <>
      <Menu course_info={course_info} />
      <HeaderInfo course_info={course_info} />
      <Container>
        {loadingVideos ? (
          <>
            <FiLoader size={20} />
            <span>{i18n.t('loading')}...</span>
          </>
        ) : (
          <>
            {!videos.length ? (
              <h4>{i18n.t('videos.no_content')}</h4>
            ) : (
              <VideosContainer>
                <div className="react-vimeo">
                  <h6>
                    {isLoadingWatchedInfo ? (
                      <FiLoader size={25} />
                    ) : (
                      <span>{playingVideo.title}</span>
                    )}
                  </h6>

                  {playingVideo.url ? (
                    <Vimeo
                      video={playingVideo.url}
                      width="640"
                      height="360"
                      onEnd={
                        () => handleCheckWatchedVideo()
                        // eslint-disable-next-line react/jsx-curly-newline
                      }
                    />
                  ) : null}
                </div>
                <div className="video-list">
                  {loadingVideos ? (
                    <FiLoader size={25} />
                  ) : (
                    <ol>
                      <div>
                        <h5>{i18n.t('videos.title')}</h5>
                        <span>{i18n.t('videos.message')}</span>
                      </div>

                      {videos.map(video => (
                        <div className="video-titles" key={video.id}>
                          <button
                            type="button"
                            onClick={() => showVideo(video)}
                          >
                            <li>
                              {video.title}

                              {video.watched ? (
                                <FiCheckCircle size={14} />
                              ) : null}
                            </li>
                          </button>
                        </div>
                      ))}
                    </ol>
                  )}
                </div>
              </VideosContainer>
            )}
          </>
        )}

        <Modal show={showQuiz} onHide={() => setShowQuiz(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{i18n.t('videos.quiz_title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Quiz
              video_id={playingVideo.id}
              module_id={params.module_id}
              student_id={student.id}
            />
          </Modal.Body>
        </Modal>
      </Container>

      <Footer />
    </>
  );
};

export default Videos;
