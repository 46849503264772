import styled from 'styled-components';
import { Container as ContainerStyled } from 'react-bootstrap';
import { shade } from 'polished';

export const Container = styled(ContainerStyled)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 50px;

  svg {
    margin-right: 7px;
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  p {
    text-align: center;
    line-height: 1.7;

    span {
      margin-right: 5px;
      background: #312e38;
      padding: 5px 12px;
      border-radius: 50%;
    }
  }

  .divider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    color: #ededed;

    .line {
      background-color: #ededed;
      height: 1px;
      width: 20%;
      margin: 0rem 20px;
    }
  }

  .line-break {
    margin-top: 10px;

    p {
      text-align: left;
    }
  }

  div {
    width: 100%;

    ol {
      list-style-type: decimal;

      li {
        list-style-type: decimal;

        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: left;
        margin-top: 15px;
        border-radius: 10px;
        padding: 20px;
        background: ${shade(0.2, '#312E38')};

        p {
          text-align: left;
        }
      }
    }
  }
`;
