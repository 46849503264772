import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Modules from '../pages/Modules';
import Videos from '../pages/Videos';
import ComplementaryMaterials from '../pages/ComplementaryMaterials';
import Exam from '../pages/Exam';
import ExamQuestions from '../pages/Exam/questions';
import Forum from '../pages/Forum';
import TopicContent from '../pages/Forum/Topic';

import AmbevT1T2 from '../pages/PowerBI/AmbevT1T2';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Login} />
    <Route path="/dash/wfa55s" component={AmbevT1T2} />

    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/course/:course_id/modules/" component={Modules} isPrivate />
    <Route
      path="/course/:course_id/module/:module_id/"
      component={Videos}
      isPrivate
    />
    <Route
      path="/complementary_materials/course/:course_id/module/:module_id/"
      component={ComplementaryMaterials}
      isPrivate
    />
    <Route
      path="/exam/course/:course_id/module/:module_id/"
      component={Exam}
      isPrivate
    />
    <Route
      path="/exam/questions/:exam_id/:course_id/:module_id/"
      component={ExamQuestions}
      isPrivate
    />
    <Route
      path="/forum/course/:course_id/module/:module_id/"
      component={Forum}
      isPrivate
    />
    <Route
      path="/topic/:topic_id/forum/:forum_id/module/:module_id/course/:course_id/"
      component={TopicContent}
      isPrivate
    />
  </Switch>
);

export default Routes;
