import React, { useRef, useCallback, useState } from 'react';
import { FiUser, FiLock, FiLoader } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { i18n } from '../../translate/i18n';

import getValidationErrors from '../../utils/getValidationErrors';

import { Body, Content, Background } from './styles';

import Input from '../../components/Input';
import Button from '../../components/Button';
import logo from '../../assets/logo-branca.png';

interface SignInFormData {
  cpf: string;
  password: string;
}

const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const { signIn } = useAuth();
  const { addToast } = useToast();

  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cpf: Yup.string().required(i18n.t('login.popover_user')),
          password: Yup.string().required(i18n.t('login.popover_password')),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setIsLoading(true);

        await signIn({
          cpf: data.cpf,
          password: data.password,
        });

        setIsLoading(false);
        history.push('/dashboard');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          type: 'error',
          title: i18n.t('login.error_title'),
          description: i18n.t('login.error_message'),
        });
        setIsLoading(false);
      }
    },
    [signIn, addToast, history],
  );

  return (
    <Body>
      <Content>
        <img src={logo} alt="Logo" />
        <h4>{i18n.t('login.title1')}</h4>

        <div>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>{i18n.t('login.title2')}</h1>

            <Input
              name="cpf"
              placeholder={i18n.t('login.placeholder_user')}
              icon={FiUser}
              hidden={false}
            />
            <Input
              name="password"
              type="password"
              placeholder={i18n.t('login.placeholder_password')}
              icon={FiLock}
              hidden={false}
            />

            <Button disabled={isLoading} type="submit">
              {isLoading ? (
                <FiLoader size={20} />
              ) : (
                <> {i18n.t('login.button')} </>
              )}
            </Button>
          </Form>
        </div>
      </Content>
      <Background />
    </Body>
  );
};

export default Login;
