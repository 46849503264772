import styled from 'styled-components';

import { Navbar as Navigation } from 'react-bootstrap';

export const Navbar = styled(Navigation)`
  background-color: #123456;
  width: 100%;
  height: 75px;
  position: absolute;
  bottom: 0;
  background: #28262e;

  div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 2px 0;

    div {
      display: flex;
      justify-content: center;
      flex-direction: row;

      a {
        margin-right: 5px;
        transition: 0.2s;

        &:hover {
          color: #009ee2;
          transition: 0.2s;
        }
      }
    }
  }

  span {
    margin-bottom: 5px;
    a {
      margin-left: 2px;
      text-decoration: none;
      transition: 0.2s;

      &:hover {
        color: #009ee2;
        transition: 0.2s;
      }
    }
  }
`;
