import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FiLoader } from 'react-icons/fi';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

import { i18n } from '../../../translate/i18n';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

import Menu from '../../../components/Menu';
import Footer from '../../../components/Footer';
import NormalButton from '../../../components/NormalButton';
import RadioInput from '../../../components/RadioButton';
import HeaderInfo from '../../../components/HeaderInfo';

import { Container } from './styles';

interface OptionsData {
  id: number;
  option: string;
  correct: boolean;
}

interface QuestionData {
  id: number;
  question: string;
  essay_question: boolean;
  options: OptionsData[];
}

interface ExamData {
  id: number;
  title: string;
  description: string;
  essay_questions: boolean;
  complementary: string;
}

interface ModuleIdParams {
  exam_id: string;
  module_id: string;
  course_id: string;
}

const ExamQuestions: React.FC = () => {
  const [questions, setQuestions] = useState<QuestionData[]>([]);
  const [exam, setExam] = useState<ExamData>();
  const [essayQuestions, setEssayQuestions] = useState();
  const [loadingExam, setLoadingExam] = useState(false);
  const [loadingSendExam, setLoadingSendExam] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { params } = useRouteMatch<ModuleIdParams>();
  const { student } = useAuth();

  const course_info = {
    course_id: params.course_id,
    module_id: params.module_id,
  };

  const history = useHistory();

  const getExam = useCallback(async () => {
    try {
      setLoadingExam(true);
      await api.get(`/questions/exam/${params.exam_id}`).then(response => {
        setQuestions(response.data);
      });

      await api
        .get(
          `/exam/${params.course_id}/${params.module_id}/${student.id}/${params.exam_id}`,
        )
        .then(response => {
          setExam(response.data);
          setEssayQuestions(response.data.essay_questions);
        });
      setLoadingExam(false);
    } catch (err) {
      setLoadingExam(true);
      addToast({
        type: 'error',
        title: i18n.t('errors.error'),
      });
      setLoadingExam(false);
    }
  }, [
    addToast,
    params.exam_id,
    params.module_id,
    params.course_id,
    student.id,
  ]);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoadingSendExam(true);
        const student_id = student.id;

        await api.post(
          `/questions/exam/${params.exam_id}/${student_id}/${params.module_id}/${params.course_id}/${essayQuestions}/`,
          data,
        );
        setLoadingSendExam(false);

        history.push(
          `/exam/course/${params.course_id}/module/${params.module_id}/`,
        );
      } catch (err) {
        setLoadingSendExam(true);
        addToast({
          type: 'error',
          title: i18n.t('errors.error'),
        });
        setLoadingSendExam(false);
      }
    },
    [
      addToast,
      params.module_id,
      params.exam_id,
      params.course_id,
      history,
      essayQuestions,
      student.id,
    ],
  );

  useEffect(() => {
    getExam();
  }, [getExam]);

  return (
    <>
      <Menu course_info={course_info} />
      <HeaderInfo course_info={course_info} />

      <Container>
        {loadingExam ? (
          <>
            <FiLoader size={20} />
            <span>{i18n.t('loading')}...</span>
          </>
        ) : (
          <>
            <h6>{exam?.title}</h6>
            <p>{exam?.description}</p>

            {exam?.complementary ? (
              <>
                <div className="divider">
                  <div className="line" />
                </div>
                <h6>Enunciado</h6>
                <ReactMarkdown
                  remarkPlugins={[gfm]}
                  // eslint-disable-next-line react/no-children-prop
                  children={exam?.complementary}
                  className="line-break"
                />
              </>
            ) : null}

            {!questions.length ? (
              <h4>Não há questões cadastradas para esta avaliação.</h4>
            ) : (
              <>
                <div>
                  <Form name="exam" ref={formRef} onSubmit={handleSubmit}>
                    <ol>
                      {questions.map((question, index) => (
                        <li key={question.id}>
                          <p>
                            <span>{index + 1}</span>
                            {question.question}
                          </p>
                          <RadioInput
                            name={String(question.id)}
                            options={question.options}
                            required
                          />
                        </li>
                      ))}
                    </ol>
                    <NormalButton disabled={loadingSendExam} type="submit">
                      {loadingSendExam ? (
                        <>
                          <FiLoader size={20} />
                          <span>Enviando...</span>
                        </>
                      ) : (
                        <span>{i18n.t('exam_questions.button')}</span>
                      )}
                    </NormalButton>
                  </Form>
                </div>
              </>
            )}
          </>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default ExamQuestions;
