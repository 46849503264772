import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    const { config, response } = err;

    if (config.url !== 'student_sessions' && response) {
      console.log(response);

      if (response.status === 403) {
        localStorage.removeItem('@EADFabet:token');
        localStorage.removeItem('@EADFabet:student');
        localStorage.removeItem('i18nextLng');

        window.location.reload();
      }
    }
  },
);

export default api;
