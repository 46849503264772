/* eslint-disable react/jsx-one-expression-per-line */

import React, { useState, useCallback, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { FiLoader } from 'react-icons/fi';

import DashboardMenu from '../../components/DashboardMenu';
import Footer from '../../components/Footer';
import NormalButton from '../../components/NormalButton';
import ProgressBar from '../../components/ProgressBar';

import api from '../../services/api';
import { i18n } from '../../translate/i18n';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

import { Container, Module, Teacher, TeacherMobile } from './styles';

interface CourseIdParams {
  course_id: string;
}

interface TeacherAvatar {
  url: string;
}

interface TeacherData {
  name: string;
  avatar: TeacherAvatar;
}

interface ModuleData {
  id: number;
  name: string;
  description: string;
  teacher: TeacherData;
  percentage: number;
}

const Modules: React.FC = () => {
  const [modules, setModules] = useState<ModuleData[]>([]);
  const [course, setCourse] = useState({ name: '' });
  const [isLoadingModules, setIsLoadingModules] = useState(false);
  const [isLoadingCourse, setIsLoadingCourse] = useState(false);

  const { student } = useAuth();
  const { addToast } = useToast();
  const { params } = useRouteMatch<CourseIdParams>();

  const getCourse = useCallback(async () => {
    try {
      setIsLoadingCourse(true);
      await api.get(`/course_name/${params.course_id}`).then(response => {
        const course_name = response.data;

        setCourse(course_name);
      });

      setIsLoadingCourse(false);
    } catch (err) {
      setIsLoadingCourse(true);
      addToast({
        type: 'error',
        title: i18n.t('errors.error'),
      });
      setIsLoadingCourse(false);
    }
  }, [addToast, params.course_id]);

  const getModules = useCallback(async () => {
    try {
      setIsLoadingModules(true);
      await api
        .get(`/module_course/${params.course_id}/${student.id}`)
        .then(response => {
          setModules(response.data);
        });

      setIsLoadingModules(false);
    } catch (err) {
      setIsLoadingModules(true);
      addToast({
        type: 'error',
        title: i18n.t('errors.error'),
      });
      setIsLoadingModules(false);
    }
  }, [addToast, params.course_id, student.id]);

  useEffect(() => {
    getCourse();
    getModules();
  }, [getCourse, getModules]);

  return (
    <>
      <DashboardMenu />
      <Container>
        <div className="course-info">
          <span>
            Curso:
            {isLoadingCourse ? (
              <FiLoader size={25} />
            ) : (
              <span>{course.name}</span>
            )}
          </span>
          <Link to="/dashboard">
            <NormalButton type="button">
              {i18n.t('module.back_to_courses')}
            </NormalButton>
          </Link>
        </div>

        <h5>Módulos</h5>

        {isLoadingModules ? (
          <FiLoader size={25} />
        ) : (
          <div className="modules">
            {!modules.length ? (
              <h4>Não há nenhum módulo disponível no momento.</h4>
            ) : (
              <>
                {modules.map(module => (
                  <Link to={`/course/${params.course_id}/module/${module.id}/`}>
                    <Module key={module.id}>
                      <div className="module">
                        <div className="module-info">
                          <h6>{module.name}</h6>
                          <span>{module.description}</span>
                          <TeacherMobile>
                            <img src={module.teacher.avatar.url} alt="avatar" />

                            <div>
                              <span>{i18n.t('module.teacher')}</span>
                              <span className="teacher-name">
                                {module.teacher.name}
                              </span>
                            </div>
                          </TeacherMobile>

                          <div className="progress-bar">
                            <span>
                              {module.percentage === null ? (
                                `0% ${i18n.t('module.watched_videos')}`
                              ) : (
                                <>
                                  {module.percentage}%{' '}
                                  {i18n.t('module.watched_videos')}
                                </>
                              )}
                            </span>
                            <ProgressBar percentage={module.percentage} />
                          </div>

                          <Link
                            to={`/course/${params.course_id}/module/${module.id}/`}
                          >
                            <NormalButton type="button">
                              {i18n.t('module.button')}
                            </NormalButton>
                          </Link>
                        </div>
                        <Teacher>
                          <img src={module.teacher.avatar.url} alt="avatar" />

                          <div>
                            <span>{i18n.t('module.teacher')}</span>
                            <span className="teacher-name">
                              {module.teacher.name}
                            </span>
                          </div>
                        </Teacher>
                      </div>
                    </Module>
                  </Link>
                ))}
              </>
            )}
          </div>
        )}
      </Container>

      <Footer />
    </>
  );
};

export default Modules;
