import React, { useState, useCallback } from 'react';
import { FaSignOutAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { i18n } from '../../translate/i18n';
import { useAuth } from '../../hooks/auth';
import logo from '../../assets/fabet-branco.png';
import { Navbar, Profile, ProfileInfo, BurgerMenu, MobileMenu } from './styles';

interface CourseInfo {
  course_id: string;
  module_id: string;
}

interface HeaderInfoProps {
  course_info: CourseInfo;
}

const Menu: React.FC<HeaderInfoProps> = course_info => {
  const [visibleProfile, setVisibleProfile] = useState(false);
  const [visibleMenu, setVisibleMenu] = useState(false);

  const { signOut, student } = useAuth();

  const { course_id, module_id } = course_info.course_info;

  const showProfile = useCallback(() => {
    if (visibleProfile) {
      setVisibleProfile(false);
    } else {
      setVisibleProfile(true);
    }
  }, [visibleProfile]);

  const showMenu = useCallback(() => {
    if (visibleMenu) {
      setVisibleMenu(false);
    } else {
      setVisibleMenu(true);
    }
  }, [visibleMenu]);

  return (
    <>
      <Navbar>
        <Link to="/dashboard">
          <img
            src={logo}
            width="100"
            className="d-inline-block align-top logo"
            alt="Fabet logo"
          />
        </Link>

        <div className="mobile-menu">
          <BurgerMenu
            isOpen={visibleMenu}
            menuClicked={() => showMenu()}
            width={18}
            height={15}
            strokeWidth={2}
            rotate={0}
            color="white"
            borderRadius={0}
            animationDuration={0.3}
          />
          <MobileMenu visible={visibleMenu}>
            <Link className="courses" to="/dashboard">
              {i18n.t('menu.courses')}
            </Link>
            <Link to={`/course/${course_id}/modules/`}>
              {i18n.t('menu.modules')}
            </Link>
            <Link to={`/course/${course_id}/module/${module_id}`}>
              {i18n.t('menu.videos')}
            </Link>
            <Link
              to={`/complementary_materials/course/${course_id}/module/${module_id}/`}
            >
              {i18n.t('menu.complementary_materials')}
            </Link>
            <Link to={`/exam/course/${course_id}/module/${module_id}/`}>
              {i18n.t('menu.exam')}
            </Link>
            <Link to={`/forum/course/${course_id}/module/${module_id}/`}>
              {i18n.t('menu.forum')}
            </Link>
          </MobileMenu>
        </div>
        <div className="links">
          <Link className="courses" to="/dashboard">
            {i18n.t('menu.courses')}
          </Link>
          <span>/</span>
          <Link to={`/course/${course_id}/modules/`}>
            {i18n.t('menu.modules')}
          </Link>
          <Link to={`/course/${course_id}/module/${module_id}`}>
            {i18n.t('menu.videos')}
          </Link>
          <Link
            to={`/complementary_materials/course/${course_id}/module/${module_id}/`}
          >
            {i18n.t('menu.complementary_materials')}
          </Link>
          <Link to={`/exam/course/${course_id}/module/${module_id}/`}>
            {i18n.t('menu.exam')}
          </Link>
          <Link to={`/forum/course/${course_id}/module/${module_id}/`}>
            {i18n.t('menu.forum')}
          </Link>
        </div>
        <div className="profile">
          <Profile onClick={() => showProfile()}>
            <img
              src={`https://ui-avatars.com/api/?name=${student.name}`}
              alt="avatar"
            />
          </Profile>
          <ProfileInfo visible={visibleProfile}>
            <Link to="/" onClick={signOut}>
              <span>{i18n.t('menu.logout')}</span>
              <FaSignOutAlt size={17} />
            </Link>
          </ProfileInfo>
        </div>
      </Navbar>
    </>
  );
};

export default Menu;
