import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FiLoader } from 'react-icons/fi';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { i18n } from '../../translate/i18n';
import { useAuth } from '../../hooks/auth';

import Menu from '../../components/Menu';
import Footer from '../../components/Footer';
import HeaderInfo from '../../components/HeaderInfo';

import { Container, ComplementaryMaterialsPDF } from './styles';

interface MaterialData {
  id: number;
  name: string;
  url: string;
}

interface ModuleIdParams {
  module_id: string;
  course_id: string;
}

const ComplementaryMaterials: React.FC = () => {
  const [materials, setMaterials] = useState<MaterialData[]>([]);
  const [currentMaterial, setCurrentMaterial] = useState({
    id: 1,
    name: 'Selecione um material',
    url: '',
  });
  const [isLoadingMaterials, setIsLoadingMaterials] = useState(false);

  const { student } = useAuth();
  const { addToast } = useToast();
  const { params } = useRouteMatch<ModuleIdParams>();

  const getMaterials = useCallback(async () => {
    try {
      setIsLoadingMaterials(true);
      await api
        .get(
          `/complementary_material/${params.course_id}/${params.module_id}/${student.id}`,
        )
        .then(response => {
          setMaterials(response.data);
          const material: any = Object.values(response.data[0]);
          setCurrentMaterial({
            id: material[0],
            name: material[1],
            url: material[2],
          });
        });

      setIsLoadingMaterials(false);
    } catch (err) {
      setIsLoadingMaterials(true);
      addToast({
        type: 'error',
        title: i18n.t('errors.error'),
      });
      setIsLoadingMaterials(false);
    }
  }, [addToast, params.module_id, student.id, params.course_id]);

  const course_info = {
    course_id: params.course_id,
    module_id: params.module_id,
    goTo: 'Vídeos',
    url: `/course/${params.course_id}/module/${params.module_id}/`,
  };

  const showMaterial = useCallback(async material => {
    setCurrentMaterial({
      id: material.id,
      name: material.name,
      url: material.url,
    });
  }, []);

  useEffect(() => {
    getMaterials();
  }, [getMaterials]);

  return (
    <>
      <Menu course_info={course_info} />
      <HeaderInfo course_info={course_info} />
      <Container>
        {isLoadingMaterials ? (
          <>
            <FiLoader size={20} />
            <span>{i18n.t('loading')}...</span>
          </>
        ) : (
          <>
            {!materials.length ? (
              <h4>{i18n.t('videos.no_content')}</h4>
            ) : (
              <ComplementaryMaterialsPDF>
                <div className="pdf-container">
                  <h6>
                    Material:
                    {isLoadingMaterials ? (
                      <FiLoader size={25} />
                    ) : (
                      <span>{currentMaterial.name}</span>
                    )}
                  </h6>

                  <div>
                    <iframe
                      title="viewer"
                      src={currentMaterial.url}
                      width="640"
                      height="480"
                    />
                  </div>
                </div>
                <div className="material-list">
                  {isLoadingMaterials ? (
                    <FiLoader size={25} />
                  ) : (
                    <ol>
                      <div>
                        <h5>{i18n.t('complementary_materials.title')}</h5>
                        <span>{i18n.t('complementary_materials.text')}</span>
                      </div>

                      {materials.map(material => (
                        <div className="material-titles" key={material.id}>
                          <button
                            type="button"
                            onClick={() => showMaterial(material)}
                          >
                            <li>{material.name}</li>
                          </button>
                        </div>
                      ))}
                    </ol>
                  )}
                </div>
              </ComplementaryMaterialsPDF>
            )}
          </>
        )}
      </Container>

      <Footer />
    </>
  );
};

export default ComplementaryMaterials;
