import styled from 'styled-components';

type ProgressBarProps = {
  percentage: number;
};

export const Container = styled.div<ProgressBarProps>`
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background: rgb(50, 50, 56);
  overflow: hidden;

  div {
    content: '';
    position: absolute;
    width: ${percentage =>
      percentage.percentage === null ? 0 : percentage.percentage}% !important;
    height: 100%;
    top: 0px;
    left: 0px;
    background: #009ee2;
    transition: width 0.4s ease-in-out 0s;
  }
`;
