import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/fabet-branco.png';
import { Navbar, BurgerMenu, MobileMenu } from './styles';

import { i18n } from '../../translate/i18n';

import { useAuth } from '../../hooks/auth';

const Menu: React.FC = () => {
  const [visibleMenu, setVisibleMenu] = useState(false);

  const { signOut, student } = useAuth();

  const showMenu = useCallback(() => {
    if (visibleMenu) {
      setVisibleMenu(false);
    } else {
      setVisibleMenu(true);
    }
  }, [visibleMenu]);

  return (
    <>
      <Navbar>
        <Link to="/dashboard">
          <img
            src={logo}
            width="100"
            className="d-inline-block align-top logo"
            alt="Fabet logo"
          />
        </Link>
        <div className="profile">
          <img
            src={`https://ui-avatars.com/api/?name=${student.name}`}
            alt="avatar"
          />
          <div>
            <span>{i18n.t('menu.welcome')}</span>
            <span className="name">{student.name}</span>
          </div>
        </div>
        <div className="mobile-menu">
          <BurgerMenu
            isOpen={visibleMenu}
            menuClicked={() => showMenu()}
            width={18}
            height={15}
            strokeWidth={2}
            rotate={0}
            color="white"
            borderRadius={0}
            animationDuration={0.3}
          />
          <MobileMenu visible={visibleMenu}>
            <Link className="courses" to="/dashboard">
              {i18n.t('menu.courses')}
            </Link>
            <Link to="/" onClick={signOut}>
              {i18n.t('menu.logout')}
            </Link>
          </MobileMenu>
        </div>
        <div className="links">
          <Link className="courses" to="/dashboard">
            {i18n.t('menu.courses')}
          </Link>
          <Link to="/" onClick={signOut}>
            {i18n.t('menu.logout')}
          </Link>
        </div>
      </Navbar>
    </>
  );
};

export default Menu;
