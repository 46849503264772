import styled from 'styled-components';
import { shade } from 'polished';
import { Container as Cont } from 'react-bootstrap';

import { device } from '../../styles/breakpoints';

export const Container = styled(Cont)`
  margin-bottom: 20px;

  div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
  }

  .course-mobile {
    @media ${device.mobile} {
      display: block !important;

      .carousel-control-next {
        right: -11px !important;
      }

      .carousel-control-prev {
        left: -11px !important;
      }

      .carousel-indicators {
        bottom: -11px !important;
      }
    }
    @media ${device.desktop} {
      display: none;
    }
    @media ${device.laptop} {
      display: none;
    }
    @media ${device.desktopL} {
      display: none;
    }
  }

  .course-desktop {
    @media ${device.mobile} {
      display: none;
    }
  }

  h2 {
    margin: 20px 0;

    @media ${device.mobile} {
      text-align: center;
    }
  }

  svg {
    text-align: center;
    margin-right: 5px;
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  a {
    margin: 10px 10px;

    @media ${device.mobile} {
      margin: 0;
    }
  }
`;

export const Course = styled.div`
  background: #28262e;
  min-height: 541px;
  width: 257px;
  padding: 17px;
  border: solid 1px #28262e;

  @media ${device.mobile} {
    padding: 30px;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  align-items: center;

  border-radius: 5px;
  transition: 0.2s;

  @media (max-width: 576px) {
    width: 100%;
  }

  &:hover {
    background: ${shade(0.2, '#28262e')};
    transition: 0.2s;
    border: solid 1px #009ee2;
  }

  img {
    width: 100%;
    border-radius: 5px;
  }

  span {
    width: 100%;
    text-align: center;
    margin-top: 7px;
  }

  .name {
    font-weight: 500;
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    color: rgb(168, 168, 179);
    margin-bottom: 15px;
  }

  div {
    width: 100%;
    display: flex;
    flex-direction: row;

    span {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
      color: #009ee2;
    }
  }

  a {
    width: 100%;

    button {
      height: 35px;
    }
  }
`;

export const Header = styled.div``;
export const Content = styled.div``;
export const CardFooter = styled.div``;
