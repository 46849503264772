import React from 'react';
import { FiInstagram, FiFacebook, FiLinkedin } from 'react-icons/fi';
import { Container } from 'react-bootstrap';

import { i18n } from '../../translate/i18n';
import { Navbar } from './styles';

const Footer: React.FC = () => {
  return (
    <Navbar sticky="bottom">
      <Container>
        <div>
          <span>
            © {i18n.t('footer.text')} -
            <a
              href="http://www.fabetsp.com.br"
              rel="noopener noreferrer"
              target="_blank"
            >
              Fabet
            </a>
          </span>
          <div>
            <a
              href="https://www.instagram.com/fabetfilial/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FiInstagram size={18} />
            </a>
            <a
              href="https://www.facebook.com/fabet.filial/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FiFacebook size={18} />
            </a>
            <a
              href="https://www.linkedin.com/in/fabet-sp-b70a4a113/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <FiLinkedin size={18} />
            </a>
          </div>
        </div>
      </Container>
    </Navbar>
  );
};

export default Footer;
