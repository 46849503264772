/* eslint-disable react/no-children-prop */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { FiLoader, FiMessageCircle } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import * as Yup from 'yup';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import getValidationErrors from '../../../utils/getValidationErrors';

import Menu from '../../../components/Menu';
import HeaderInfo from '../../../components/HeaderInfo';
import Footer from '../../../components/Footer';
import NormalButton from '../../../components/NormalButton';
import TextArea from '../../../components/TextArea';

import { Container, Topic, Messages, Message } from './styles';

interface TagParams {
  label: string;
}

interface StudentParams {
  name: string;
}

interface TopicParams {
  id: number;
  title: string;
  text: string;
  updated_at: string;
  student: StudentParams;
  tag: TagParams;
}

interface MessageParams {
  id: number;
  message: string;
  updated_at: string;
  student: StudentParams;
}

interface ContentParams {
  topic: TopicParams[];
  messages: MessageParams[];
}

interface ModuleIdParams {
  module_id: string;
  course_id: string;
  topic_id: string;
  forum_id: string;
}

interface AnswerData {
  message: string;
  forum_id: number;
  topic_id: number;
  student_id: number;
}

const TopicContent: React.FC = () => {
  const [topicContent, setTopicContent] = useState<ContentParams[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { student } = useAuth();
  const { addToast } = useToast();
  const { params } = useRouteMatch<ModuleIdParams>();

  const formRef = useRef<FormHandles>(null);

  const course_info = {
    course_id: params.course_id,
    module_id: params.module_id,
  };

  const getTopicContent = useCallback(async () => {
    try {
      setIsLoading(true);
      await api
        .get(`/forum/topic/${params.topic_id}/content`)
        .then(response => {
          const content = response.data;

          setTopicContent(content);
        });

      setIsLoading(false);
    } catch (err) {
      setIsLoading(true);
      addToast({
        type: 'error',
        title:
          'Ocorreu um erro ao carregar, verifique sua conexão com a internet.',
      });
      setIsLoading(false);
    }
  }, [addToast, params.topic_id]);

  const handleAddTopic = useCallback(
    async (data: AnswerData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          message: Yup.string().required('Digite sua mensagem'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setIsLoading(true);

        data.forum_id = parseInt(params.forum_id, 10);
        data.topic_id = parseInt(params.topic_id, 10);
        data.student_id = parseInt(student.id, 10);

        await api.post('/forum/topic/message', data);

        addToast({
          type: 'success',
          title: `Resposta adicionada com sucesso!`,
        });

        getTopicContent();
        setIsLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
        addToast({
          type: 'error',
          title: 'Erro ao criar tópico.',
          description: 'Ocorreu um erro ao responder o tópico.',
        });
        setIsLoading(false);
      }
    },
    [addToast, params.topic_id, params.forum_id, student.id, getTopicContent],
  );

  useEffect(() => {
    getTopicContent();
  }, [getTopicContent]);

  return (
    <>
      <Menu course_info={course_info} />
      <HeaderInfo course_info={course_info} />
      <Container>
        {isLoading ? (
          <>
            <div className="spinner">
              <FiLoader size={20} />
              <span>Carregando...</span>
            </div>
          </>
        ) : (
          <>
            <Link
              to={`/forum/course/${params.course_id}/module/${params.module_id}/`}
            >
              Voltar para o fórum do módulo.
            </Link>
            {topicContent.map(topic => (
              <Topic key={topic.topic[0]?.id}>
                <div className="avatar">
                  <img
                    src={`https://ui-avatars.com/api/?name=${topic.topic[0]?.student.name}`}
                    alt="avatar"
                  />
                </div>
                <div className="content">
                  <header>
                    <span>{topic.topic[0]?.student.name}</span>
                    <time>{topic.topic[0]?.updated_at}</time>
                  </header>
                  <h5>{topic.topic[0]?.title}</h5>
                  <div className="divider" />
                  <ReactMarkdown
                    remarkPlugins={[gfm]}
                    children={topic.topic[0]?.text}
                    className="line-break"
                  />
                  <div className="divider" />
                  <footer>
                    <span>{topic.topic[0]?.tag.label}</span>
                  </footer>
                </div>
              </Topic>
            ))}

            <div className="divider">
              <div className="line" />
              <FiMessageCircle size={20} />
              <span>Respostas</span>
              <div className="line" />
            </div>

            <Messages>
              {topicContent.map(message => (
                <>
                  {message.messages.length ? (
                    <>
                      {message.messages.map(m => (
                        <Message key={m.id}>
                          <div className="avatar">
                            <img
                              src={`https://ui-avatars.com/api/?name=${m.student.name}`}
                              alt="avatar"
                            />
                          </div>
                          <div className="content">
                            <header>
                              <span>{m.student.name}</span>
                              <time>{m.updated_at}</time>
                            </header>
                            <div className="divider" />
                            <ReactMarkdown
                              remarkPlugins={[gfm]}
                              children={m.message}
                              className="line-break"
                            />
                          </div>
                        </Message>
                      ))}
                    </>
                  ) : (
                    <div className="no-answers">
                      <h5>
                        Por enquanto ninguém respondeu este tópico, seja o
                        primeiro!
                      </h5>
                    </div>
                  )}
                </>
              ))}
            </Messages>

            <Form ref={formRef} onSubmit={handleAddTopic}>
              <TextArea
                name="message"
                placeholder="Digite uma mensagem e clique em enviar para responder o tópico."
                icon={FiMessageCircle}
              />

              <NormalButton disabled={isLoading} type="submit">
                {isLoading ? (
                  <>
                    <FiLoader size={20} /> <span>Enviando Responsta...</span>
                  </>
                ) : (
                  'Enviar'
                )}
              </NormalButton>
            </Form>
          </>
        )}
      </Container>

      <Footer />
    </>
  );
};

export default TopicContent;
