import styled from 'styled-components';
import { shade } from 'polished';
import { Container as ContainerStyled } from 'react-bootstrap';

import { device } from '../../styles/breakpoints';

export const Container = styled(ContainerStyled)`
  margin-bottom: 20px;

  .course-info {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin: 0 0 40px 15px;
    padding-bottom: 15px;
    border-bottom: 2px solid #28262e;

    h5 {
      text-align: left;
    }

    a {
      max-width: 250px;
      margin-top: 15px;
      button {
        width: 250px;
      }
    }

    span {
      margin-left: 8px;
    }
  }

  h5 {
    text-align: center;
  }

  .modules {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;

    a {
      margin: 10px 10px;
    }
  }

  svg {
    text-align: center;
    margin-left: 8px;
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Module = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  border: 1px solid #28262e;
  background: #28262e;
  width: 100%;
  padding: 15px 20px 15px 20px;

  @media (max-width: 576px) {
    margin: 10px 0;
  }

  border-radius: 5px;
  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, '#28262e')};
    transition: 0.2s;
    border: solid 1px #009ee2;
  }

  a {
    margin-top: 15px;
    width: 150px;

    button {
      width: 150px;
    }
  }

  .module {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .module-info {
      display: flex;
      flex-direction: column;
      width: 86%;
      padding-right: 10px;
      border-right: 2px solid #312e38;
      margin-right: 20px;

      @media ${device.mobile} {
        width: 100%;
      }

      h6 {
        margin-bottom: 15px;
      }

      span {
        color: rgb(168, 168, 179);
      }

      @media (max-width: 576px) {
        border: 0;
        margin: 0;
        padding: 0;
      }

      .progress-bar {
        background: none !important;
        text-align: left;
        margin: 15px 0;

        span {
          margin-bottom: 5px;
          font-size: 14px;
          font-weight: 500;
          color: #009ee2;
        }
      }

      a {
        margin: 0;
      }
    }
  }
`;

export const Teacher = styled.div`
  display: flex;
  width: 20%;

  img {
    width: 56px;
    height: 56px;
    object-fit: cover;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5px;
    line-height: 24px;
  }

  span {
    color: #f4ede8;
  }

  .teacher-name {
    color: #ea333a;
  }

  @media (max-width: 576px) {
    display: none;
  }
`;

export const TeacherMobile = styled.div`
  display: none;

  @media (max-width: 576px) {
    display: flex;
    width: 100%;

    margin: 15px 0 8px;

    img {
      width: 56px;
      height: 56px;
      object-fit: cover;
      border-radius: 50%;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 5px;
      line-height: 24px;
    }

    span {
      color: #f4ede8;
    }

    .teacher-name {
      color: #ea333a;
    }
  }
`;
