import React, { useState, useCallback, useEffect } from 'react';
import { FiLoader } from 'react-icons/fi';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';

import { Container } from './styles';

interface CourseInfo {
  course_id: string;
  module_id: string;
}

interface HeaderInfoProps {
  course_info: CourseInfo;
}

const HeaderInfo: React.FC<HeaderInfoProps> = course_info => {
  const [isLoadingCourseName, setIsLoadingCourseName] = useState(false);
  const [isLoadingModuleName, setIsLoadingModuleName] = useState(false);
  const [courseName, setCourseName] = useState({ name: '' });
  const [moduleName, setModuleName] = useState({ name: '' });

  const { addToast } = useToast();

  const { course_id, module_id } = course_info.course_info;

  const getCourseAndModuleInfo = useCallback(async () => {
    try {
      setIsLoadingCourseName(true);
      setIsLoadingModuleName(true);
      await api.get(`/course_name/${course_id}`).then(response => {
        const course_name = response.data;
        setCourseName(course_name);
      });

      await api.get(`/module_name/${module_id}`).then(response => {
        const module_name = response.data;
        setModuleName(module_name);
      });

      setIsLoadingCourseName(false);
      setIsLoadingModuleName(false);
    } catch (err) {
      setIsLoadingCourseName(true);
      setIsLoadingModuleName(true);

      addToast({
        type: 'error',
        title:
          'Ocorreu um erro ao carregar, verifique sua conexão com a internet.',
      });
      setIsLoadingCourseName(false);
      setIsLoadingModuleName(false);
    }
  }, [addToast, course_id, module_id]);

  useEffect(() => {
    getCourseAndModuleInfo();
  }, [getCourseAndModuleInfo]);

  return (
    <Container>
      <div className="course-info">
        <span>
          Curso:
          {isLoadingCourseName ? (
            <FiLoader size={25} />
          ) : (
            <span>{courseName.name}</span>
          )}
        </span>
        <span>
          Módulo:
          {isLoadingModuleName ? (
            <FiLoader size={25} />
          ) : (
            <span>{moduleName.name}</span>
          )}
        </span>
      </div>
    </Container>
  );
};

export default HeaderInfo;
