/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiLoader } from 'react-icons/fi';

import Carousel from 'react-bootstrap/Carousel';
import { i18n } from '../../translate/i18n';

import DashboardMenu from '../../components/DashboardMenu';
import Footer from '../../components/Footer';
import Button from '../../components/Button';
import ProgressBar from '../../components/ProgressBar';

import api from '../../services/api';
import { Container, Course, Header, Content, CardFooter } from './styles';

import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';

interface Avatar {
  url: string;
}

interface CourseData {
  id: number;
  name: string;
  description: string;
  avatar: Avatar;
  percentage: number;
}

const Dashboard: React.FC = () => {
  const [courses, setCourses] = useState<CourseData[]>([]);
  const [isLoadingCourses, setIsLoadingCourses] = useState(false);

  const { addToast } = useToast();
  const { student } = useAuth();

  const getCourses = useCallback(async () => {
    try {
      setIsLoadingCourses(true);
      await api.get(`/course_student/${student.id}`).then(response => {
        setCourses(response.data);
      });

      setIsLoadingCourses(false);
    } catch (err) {
      setIsLoadingCourses(true);
      addToast({
        type: 'error',
        title: i18n.t('errors.error'),
      });
      setIsLoadingCourses(false);
    }
  }, [addToast, student.id]);

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  return (
    <>
      <DashboardMenu />

      <Container>
        <h2>{i18n.t('dashboard.courses')}</h2>

        {isLoadingCourses ? (
          <FiLoader size={25} />
        ) : (
          <>
            {!courses.length ? (
              <h4>{i18n.t('dashboard.no_videos')}</h4>
            ) : (
              <>
                <div className="course-mobile">
                  <Carousel fade>
                    {courses.map(course => (
                      <Carousel.Item key={course.id}>
                        <Link to={`course/${course.id}/modules/`}>
                          <Course key={course.id}>
                            <Header>
                              <img src={course.avatar.url} alt="avatar" />
                            </Header>
                            <Content>
                              <span className="name">{course.name}</span>
                              <span className="description">
                                {course.description}
                              </span>
                            </Content>
                            <CardFooter>
                              <div>
                                <span>
                                  {course.percentage === null ? (
                                    '0% Concluído.'
                                  ) : (
                                    <>
                                      {course.percentage}%{' '}
                                      {i18n.t('dashboard.watched_videos')}
                                    </>
                                  )}
                                </span>
                                <ProgressBar percentage={course.percentage} />
                              </div>

                              <Link to={`course/${course.id}/modules/`}>
                                <Button type="button">Acessar</Button>
                              </Link>
                            </CardFooter>
                          </Course>
                        </Link>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
                <div className="course-desktop">
                  {courses.map(course => (
                    <Link key={course.id} to={`course/${course.id}/modules/`}>
                      <Course>
                        <Header>
                          <img src={course.avatar.url} alt="avatar" />
                        </Header>
                        <Content>
                          <span className="name">{course.name}</span>
                          <span className="description">
                            {course.description}
                          </span>
                        </Content>
                        <CardFooter>
                          <div>
                            <span>
                              {course.percentage === null ? (
                                '0% Concluído.'
                              ) : (
                                <>
                                  {course.percentage}%{' '}
                                  {i18n.t('dashboard.watched_videos')}
                                </>
                              )}
                            </span>
                            <ProgressBar percentage={course.percentage} />
                          </div>

                          <Link to={`course/${course.id}/modules/`}>
                            <Button type="button">
                              {i18n.t('dashboard.button')}
                            </Button>
                          </Link>
                        </CardFooter>
                      </Course>
                    </Link>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </Container>

      <Footer />
    </>
  );
};

export default Dashboard;
