import styled from 'styled-components';
import { Container as ContainerStyled } from 'react-bootstrap';
import { shade } from 'polished';

export const Container = styled(ContainerStyled)`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  @media (max-width: 576px) {
    flex-wrap: wrap;
  }

  svg {
    margin-right: 7px;
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .exams {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 10px;
    width: 100%;

    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      background: #28262e;
      border: 1px #28262e solid;
      padding: 15px;
      border-radius: 5px;
      margin: 0 10px;
      transition: 0.2s;
      width: 100%;

      .title {
        font-weight: 500;
      }

      &:hover {
        background: ${shade(0.2, '#28262e')};
        transition: 0.2s;
        border: solid 1px #009ee2;
      }

      .nota {
        background-color: ${shade(0.2, '#ededed')} !important;
        width: fit-content;
        padding: 0 7px;
        border-radius: 5px;
        color: #333;

        span {
          margin-left: 7px;
        }
      }

      button {
        width: fit-content;
        margin-top: 10px;
      }
    }
  }
`;
