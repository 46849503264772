import styled from 'styled-components';
import { shade, lighten } from 'polished';

import { Button as StyledButton } from 'react-bootstrap';

export const Container = styled(StyledButton)`
  background: #009ee2;
  color: #312e38;
  font-weight: 500;
  transition: 0.2s;
  border: 0;

  &:hover {
    background-color: ${shade(0.2, '#009EE2')} !important;
    color: #312e38;
  }

  &:active {
    background-color: ${shade(0.2, '#009EE2')} !important;
    color: #312e38;
  }

  &:focus {
    background-color: ${shade(0.2, '#009EE2')} !important;
    color: #312e38;
  }

  &:disabled {
    background-color: ${lighten(0.2, '#009EE2')} !important;
    color: #312e38;
    cursor: not-allowed;
  }
`;
