/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { FiLoader } from 'react-icons/fi';
import QuestionCard from '../QuestionCard';

import api from '../../services/api';
import { i18n } from '../../translate/i18n';

import { Container, Button } from './styles';

interface VideoInfo {
  video_id: number;
  student_id: string;
  module_id: string;
}

interface QuestionData {
  id: number;
  question: string;
  correct_answer: string;
  incorrect_answer: any;
  answers: any;
}

export type AnswerObject = {
  question: string;
  answer: string;
  correct: boolean;
  correctAnswer: string;
};

const Quiz: React.FC<VideoInfo> = ({ video_id, module_id, student_id }) => {
  const [loading, setLoading] = useState(false);
  const [loadingStudentAnswer, setLoadingStudentAnswer] = useState(false);
  const [questions, setQuestions] = useState<QuestionData[]>([]);
  const [number, setNumber] = useState(0);
  const [userAnswers, setUserAnswers] = useState<AnswerObject[]>([]);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(true);
  const [finalMessage, setFinalMessage] = useState(false);

  const video = video_id;
  const module = parseInt(module_id, 10);
  const student = parseInt(student_id, 10);

  const getQuiz = useCallback(async () => {
    setGameOver(false);
    try {
      setLoading(true);
      await api.get(`/quiz/video/${video}`).then(response => {
        setQuestions(response.data);
      });

      setScore(0);
      setUserAnswers([]);
      setNumber(0);
      setLoading(false);
    } catch (err) {
      alert('error');
    }
  }, [video]);

  const checkAnswer = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      setLoadingStudentAnswer(true);
      if (!gameOver) {
        // User Answer
        const answer = e.currentTarget.value;
        // Check answer against correct answer
        const correct = questions[number].correct_answer === answer;
        // Add score if answer is correct
        if (correct) {
          setScore(prev => prev + 1);

          const data = {
            score: 1,
            quiz_id: questions[number].id,
            module_id: module,
            video_id: video,
            student_id: student,
          };

          await api.post('/quiz_answer/', data);
        } else {
          const data = {
            score: 0,
            quiz_id: questions[number].id,
            module_id: module,
            video_id: video,
            student_id: student,
          };

          await api.post('/quiz_answer/', data);
        }
        // Save answer in te array for user answers
        const answerObject = {
          question: questions[number].question,
          answer,
          correct,
          correctAnswer: questions[number].correct_answer,
        };

        // Check is latest answer
        if (questions.length === number + 1) {
          setFinalMessage(true);
        }

        setLoadingStudentAnswer(false);
        setUserAnswers(prev => [...prev, answerObject]);
      }
    },
    [gameOver, number, questions, module, student, video],
  );

  const nextQuestion = useCallback(() => {
    // Move on to the next question if not the last question
    const next = number + 1;

    if (next === questions.length) {
      setGameOver(true);
    } else {
      setNumber(next);
    }
  }, [number, questions]);

  useEffect(() => {
    getQuiz();
  }, [getQuiz]);

  return (
    <Container>
      {loading && <FiLoader size={25} />}
      {!loading && !gameOver && (
        <QuestionCard
          questionNr={number + 1}
          totalQuestions={questions.length}
          question={questions[number].question}
          answers={questions[number].answers}
          userAnswer={userAnswers ? userAnswers[number] : undefined}
          callback={checkAnswer}
          loadingStudentAnswer={loadingStudentAnswer}
        />
      )}
      {!gameOver &&
      !loading &&
      userAnswers.length === number + 1 &&
      number !== questions.length - 1 ? (
        <Button className="next" type="button" onClick={() => nextQuestion()}>
          {i18n.t('videos.next')}
        </Button>
      ) : null}

      {finalMessage ? (
        <>
          {score ? (
            <p className="score">
              {i18n.t('videos.score')}
              <span>{score}</span>
              {score === 1 ? (
                <span className="ml-1">{i18n.t('videos.question')}</span>
              ) : (
                <span className="ml-1">{i18n.t('videos.questions')}</span>
              )}
            </p>
          ) : null}

          <div className="final-message">
            {questions.length === score ? (
              <>
                <p>{i18n.t('videos.congratulations')}</p>
                <p>{i18n.t('videos.close')}</p>
              </>
            ) : score < questions.length && score >= 1 ? (
              <>
                <p>{i18n.t('videos.congratulations1')}</p>
                <p>{i18n.t('videos.close')}</p>
              </>
            ) : (
              <>
                <p>{i18n.t('videos.congratulations2')}</p>
                <p>{i18n.t('videos.close')}</p>
              </>
            )}
          </div>
        </>
      ) : null}
    </Container>
  );
};

export default Quiz;
