import styled, { css } from 'styled-components';
import { shade } from 'polished';
import HamburgerMenu from 'react-hamburger-menu';

import { device } from '../../styles/breakpoints';

export const BurgerMenu = styled(HamburgerMenu)`
  display: none;
  cursor: pointer;

  @media ${device.mobile} {
    display: block;
  }
`;

export const Navbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #28262e;
  padding: 5px 40px;
  margin-bottom: 30px;
  z-index: 0;
  box-shadow: rgb(18 18 20) 0px 0.1rem 0.5rem;

  @media ${device.desktopL} {
    padding: 5px 240px;
  }

  a {
    text-decoration: none;
    color: #f4ede8;
    padding: 10px 10px;
    transition: 0.2s;

    &:hover {
      color: #ea333a;
      transition: 0.2s;
    }
  }

  .profile {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;
    padding-left: 12px;
    z-index: 998;
  }

  .links {
    display: block;
  }

  .mobile-menu {
    display: none;
  }

  @media ${device.mobile} {
    width: 100%;
    max-width: 1366px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 5px 20px;

    .mobile-menu {
      display: block;
      z-index: 997;
    }

    .links {
      display: none;
    }

    img {
      width: 70px;
    }
  }
`;

export const Profile = styled.div`
  cursor: pointer;

  img {
    width: 56px;
    height: 56px;
    object-fit: cover;
    border-radius: 50%;
    border: solid 2px #fff;
  }

  @media ${device.mobile} {
    img {
      width: 48px;
      height: 48px;
    }
  }
`;

type ProfileProps = {
  visible: boolean;
};

export const ProfileInfo = styled.div<ProfileProps>`
  position: absolute;
  right: 0px;
  top: calc(100% + 24px);
  width: 256px;
  padding: 0px;
  background: #28262e;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 60%) 0px 1px 5px;
  transition: opacity 0.2s ease 0s, visibility 0.2s ease 0s;
  opacity: 1;

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    right: 19px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 8px 8px;
    border-color: transparent transparent #28262e;
  }

  ${props =>
    props.visible
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
        `}

  a {
    display: flex;
    flex-direction: row-reverse;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(225, 225, 230);
    padding: 12px 24px;
    transition: background 0.2s ease 0s;
    cursor: pointer;
    background: transparent;
    border: none;
    width: 100%;

    &:hover {
      color: rgb(225, 225, 230);
      background: ${shade(0.2, '#28262e')};
    }

    svg {
      margin-right: 15px;
      color: #009ee2;
    }
  }
`;

type MenuProps = {
  visible: boolean;
};

export const MobileMenu = styled.div<MenuProps>`
  position: absolute;
  left: 0px;
  top: 59px;
  width: 100%;
  padding: 0px;
  background: #28262e;
  box-shadow: rgb(0 0 0 / 60%) 0px 1px 5px;
  transition: opacity 0.2s ease 0s, visibility 0.2s ease 0s;
  opacity: 1;

  &::before {
    content: '';
    position: absolute;
    top: -9px;
    right: 101px;
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 0px 8px 8px;
    border-color: transparent transparent #28262e;
  }

  ${props =>
    props.visible
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
        `}

  .courses {
    display: inline-block;
    position: relative;

    &::after {
      position: absolute;
      content: '';
      border-bottom: 2px solid #312e38;
      width: 70%;
      transform: translateX(-50%);
      bottom: 0;
      left: 44%;
      padding: 12px 24px;
    }
  }

  a {
    display: flex;
    flex-direction: row-reverse;
    -webkit-box-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(225, 225, 230);
    padding: 7px 24px;
    transition: background 0.2s ease 0s;
    cursor: pointer;
    background: transparent;
    border: none;
    width: 100%;

    &:hover {
      color: rgb(225, 225, 230);
      background: ${shade(0.2, '#28262e')};
    }

    svg {
      margin-right: 15px;
      color: #009ee2;
    }
  }
`;
