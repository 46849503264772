import React from 'react';

import { Container } from './styles';

const AmbevT1T2: React.FC = () => {
  return (
    <Container>
      <iframe
        title="t1ambev"
        src="https://app.powerbi.com/view?r=eyJrIjoiZGQ3YjFkYTItZjEyNy00YWZhLTlkNzktOTk2MzVmZWUzYmU3IiwidCI6IjMwYjY4N2U5LTdkYTYtNGIzMC1hODRiLTU1NmQxZGE1ZDM1OCJ9"
        frameBorder="0"
        allowFullScreen
      />
    </Container>
  );
};

export default AmbevT1T2;
