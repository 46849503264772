import styled from 'styled-components';

import { Container as ContainerStyled } from 'react-bootstrap';

export const Container = styled(ContainerStyled)`
  margin-bottom: 30px;

  .course-info {
    display: flex;
    flex-direction: column;
    justify-content: start;

    h5 {
      text-align: left;
    }

    a {
      max-width: 250px;
      margin-bottom: 15px;
      button {
        width: 250px;
      }
    }

    span {
      margin-left: 8px;
    }
  }

  h5 {
    text-align: center;
  }

  div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
  }

  svg {
    text-align: center;
    margin-left: 8px;
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
