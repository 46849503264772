/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { FiLoader } from 'react-icons/fi';

import { AnswerObject } from '../Quiz';

import { Container, ButtonWrapper } from './styles';

type Props = {
  question: string;
  answers: string[];
  callback: (e: React.MouseEvent<HTMLButtonElement>) => void;
  userAnswer: AnswerObject | undefined;
  questionNr: number;
  totalQuestions: number;
  loadingStudentAnswer: boolean;
};

const QuestionCard: React.FC<Props> = ({
  question,
  answers,
  callback,
  userAnswer,
  questionNr,
  totalQuestions,
  loadingStudentAnswer,
}) => {
  return (
    <Container>
      <p className="number">
        Questão: {questionNr}/{totalQuestions}
      </p>
      <p dangerouslySetInnerHTML={{ __html: question }} />
      <div>
        {answers.map(answer => (
          <ButtonWrapper
            key={answer}
            correct={userAnswer?.correctAnswer === answer}
            userClicked={userAnswer?.answer === answer}
          >
            <button
              type="button"
              disabled={!!userAnswer}
              value={answer}
              onClick={callback}
            >
              {loadingStudentAnswer ? <FiLoader size={13} /> : null}
              <span dangerouslySetInnerHTML={{ __html: answer }} />
            </button>
          </ButtonWrapper>
        ))}
      </div>
    </Container>
  );
};

export default QuestionCard;
