import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { student } = useAuth();

  const Render = (location: any) => {
    if (isPrivate === !!student) {
      return <Component />;
    }
    return (
      <Redirect
        to={{
          pathname: isPrivate ? '/' : 'dashboard',
          state: { from: location },
        }}
      />
    );
  };

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return Render(location);
      }}
    />
  );
};

export default Route;
