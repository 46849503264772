import React, { createContext, useState, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { i18n } from '../../translate/i18n';
import api from '../../services/api';
import { useToast } from '../toast';
import { useAuth } from '../auth';

import { VideosInfo, VideosContextData, ShowVideo } from './types';

export const VideosContext = createContext<VideosContextData>(
  {} as VideosContextData,
);

const VideoProvider: React.FC = ({ children }) => {
  const [videos, setVideos] = useState<VideosInfo[]>([]);
  const [loadingVideos, setLoadingVideos] = useState<boolean>(false);
  const [showQuiz, setShowQuiz] = useState<boolean>(false);
  const [isLoadingWatchedInfo, setLoadingIsWatchedInfo] = useState(false);
  const [playingVideo, setPlayingVideo] = useState<ShowVideo>({
    id: 1,
    title: i18n.t('videos.select_video'),
    url: '',
    quiz: false,
  });

  const { student } = useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  // Busca os vídeos no banco de dados.
  const getVideos = useCallback(
    async (course_id: string, module_id: string) => {
      try {
        setLoadingVideos(true);

        await api
          .get(
            `/course/${course_id}/module/${module_id}/student_id/${student.id}`,
          )
          .then(response => {
            setVideos(response.data);
          });

        setLoadingVideos(false);
      } catch (err) {
        setLoadingVideos(true);
        addToast({
          type: 'error',
          title: i18n.t('errors.error'),
        });
        setLoadingVideos(false);

        history.push(`/dashboard`);
      }
    },
    [addToast, student?.id, history],
  );

  const checkWatchedVideo = useCallback(
    async (course_id: string, module_id: string) => {
      console.log('teste', course_id);

      setLoadingIsWatchedInfo(true);

      if (playingVideo.quiz) {
        setShowQuiz(true);
      }

      if (!playingVideo.watched) {
        const module = parseInt(module_id, 10);
        const course = parseInt(course_id, 10);

        const data = {
          watched: true,
          student_id: student.id,
          video_id: playingVideo.id,
          module_id: module,
          course_id: course,
        };

        try {
          await api.post('/student_watched_video', data);
        } catch (err) {
          setLoadingIsWatchedInfo(false);

          addToast({
            type: 'error',
            title: i18n.t('errors.watchedVideo'),
          });
        }

        getVideos(course_id, module_id);
      }

      setLoadingIsWatchedInfo(false);
    },
    [playingVideo, student?.id, getVideos, addToast],
  );

  // Mostra o vídeo na tela ao clicar no nome do mesmo
  const showVideo = useCallback(async video => {
    setPlayingVideo({
      id: video.id,
      title: video.title,
      url: video.url,
      quiz: video?.quiz,
      watched: video?.watched,
    });
  }, []);

  return (
    <VideosContext.Provider
      value={{
        videos,
        getVideos,
        loadingVideos,
        checkWatchedVideo,
        showVideo,
        showQuiz,
        setShowQuiz,
        isLoadingWatchedInfo,
        playingVideo,
      }}
    >
      {children}
    </VideosContext.Provider>
  );
};

function useVideos(): VideosContextData {
  const context = useContext(VideosContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { VideoProvider, useVideos };
