import styled from 'styled-components';
import {
  Container as ContainerStyled,
  Modal as ModalStyled,
} from 'react-bootstrap';
import { shade } from 'polished';

export const Container = styled(ContainerStyled)`
  margin-bottom: 20px;

  h4 {
    margin-bottom: 20px;
  }

  svg {
    margin-right: 7px;
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  button {
    margin-top: 10px;

    svg {
      margin-right: 7px;
      animation: none;
    }
  }

  div {
    h5 {
      margin-top: 15px;
    }
  }
`;

export const SearchForm = styled.div`
  width: 100%;

  form {
    display: flex;
    flex-direction: row;

    button {
      margin: 1px 1px 1px 10px;
    }
  }

  svg {
    margin-right: 7px;
    animation: none;
  }
`;

export const Topics = styled.div`
  margin-top: 20px;
`;

export const Topic = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;

  border: 1px solid #28262e;
  border-radius: 5px;
  background: #28262e;
  padding: 15px 20px 15px 20px;

  @media (max-width: 576px) {
    margin: 10px 0;
  }

  border-radius: 5px;
  transition: 0.2s;

  &:hover {
    background: ${shade(0.2, '#28262e')};
    transition: 0.2s;
    border: solid 1px #009ee2;
  }

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin-right: 20px;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-bottom: 7px;

    span {
      color: rgb(168, 168, 179);

      time {
        font-size: 0.8rem;
        font-weight: normal;
        color: rgb(115, 115, 128);
        margin-left: 0.8rem;
      }
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        background: #ea333a;
        padding: 1px 6px;
        border-radius: 15px;
        margin-right: 10px;
        color: #fff;
        font-size: 14px;
      }

      h6 {
        margin: 0;
        color: rgb(225, 225, 230);
      }
    }
  }
`;

export const Modal = styled(ModalStyled)`
  color: #e1e1e6;
  background: #121214c9;

  .modal-dialog {
    .modal-header {
      border: 0;

      button {
        color: #e1e1e6;

        span {
          font-size: 30px;
          font-weight: normal;
        }
      }
    }

    .modal-content {
      background: #202024;

      form {
        div {
          background-color: #141316;
        }

        button {
          margin-top: 20px;
        }
      }
    }

    max-width: 800px;
  }
`;
