const messages = {
  es: {
    translations: {
      login: {
        title1: 'Plataforma Docente - FABET',
        title2: 'Iniciar sesión',
        placeholder_user: 'Usuario',
        placeholder_password: 'Contraseña',
        button: 'Login',
        popover_user: 'Usuario requerido.',
        popover_password: 'Se requiere contraseña.',
        error_title: 'Error de autenticación.',
        error_message:
          'Hubo un error al iniciar sesión, verifique las credenciales.',
      },
      menu: {
        welcome: 'Bienvenido,',
        courses: 'Mis Cursos',
        logout: 'Logout',
        modules: 'Módulos del Curso Actual',
        videos: 'Videos',
        complementary_materials: 'Material de Apoyo',
        exam: 'Evaluaciones',
        forum: 'Foro',
      },
      errors: {
        error:
          'Hubo un error al cargar la información, verifique su conexión a internet.',
        watchedVideo: 'Hubo un error al guardar el video como visto.',
      },
      loading: 'Cargando',
      footer: {
        text: 'Todos los derechos reservados',
      },
      dashboard: {
        courses: 'Mis Cursos',
        no_courses: 'Actualmente no hay cursos disponibles.',
        watched_videos: 'de videos vistos.',
        button: 'Acceso',
      },
      module: {
        back_to_courses: 'Volver a MIS CURSOS',
        button: 'Acceso',
        teacher: 'Maestro:',
        watched_videos: 'de videos vistos.',
      },
      videos: {
        select_video: 'Seleccione un video para comenzar.',
        title: 'Videos',
        message: 'Haga clic en el nombre del video para reproducir.',
        no_content: 'Actualmente no hay contenido disponible.',
        quiz_title: '¡Dominando el tema!',
        next: 'Próxima pregunta',
        score: 'Respondiste correctamente',
        question: 'pregunta.',
        questions: 'preguntas.',
        congratulations: '¡Felicidades! ¡Has dominado el tema!',
        congratulations1:
          '¡No respondiste bien todas las preguntas! ¡Estudia un poco más!',
        congratulations2: '¡Tienes todas las preguntas mal! ¡Estudia más!',
        close:
          'Para cerrar, haga clic en la X en la esquina superior derecha de esta pantalla.',
      },
      complementary_materials: {
        title: 'Materiales de Apoyo',
        text: 'Haga clic en el nombre del material para verlo.',
      },
      exam: {
        status: 'Evaluación no realizada.',
        start: 'Inicie la evaluación.',
        block:
          'Evaluación bloqueada, vea primero todas las lecciones en video.',
        finish: 'Evaluación realizada.',
        button_videos: 'Acceder a videos.',
      },
      exam_questions: {
        button: 'Finalizar Evaluación.',
      },
      foro: {
        title: 'Foro',
        search_button: 'Búsqueda',
        search_placeholder: 'Ingrese un término de búsqueda ...',
        new_topic_button: 'Nuevo tema',
        no_topics: 'Aún no hay un tema, ¡siéntete libre y crea el primero!',
        success: '¡Hilo creado con éxito!',
        placeholder_title: 'Título del tema',
        placeholder_tags: 'Seleccione una etiqueta que coincida con su tema.',
        placeholder_message: 'Ingrese el mensaje sobre su tema.',
        creating: 'Creando tema',
        create_button: 'Crear tema',
      },
    },
  },
};

export { messages };
