import styled from 'styled-components';
import { Container as ContainerStyled } from 'react-bootstrap';

import { device } from '../../../styles/breakpoints';

export const Container = styled(ContainerStyled)`
  .spinner {
    display: flex;
    flex-direction: row;

    svg {
      margin-right: 7px;
      animation: spin 2s linear infinite;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .divider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    color: #ededed;

    .line {
      background-color: #ededed;
      height: 1px;
      width: 20%;
      margin: 0rem 20px;
    }

    svg {
      margin-right: 10px;
    }
  }

  a {
    margin: 8px;
  }

  form {
    margin-bottom: 20px;
    margin-top: 20px;
    position: relative;
    max-width: calc(100% - 85px);
    left: 80px;
    width: 85%;

    button {
      margin-top: 10px;
    }

    @media ${device.mobile} {
      max-width: 100%;
      width: 100%;
      left: 0px;
    }
  }
`;

export const Topic = styled.div`
  display: flex;

  background-color: rgb(32, 32, 36);
  padding: 1.4rem 2.4rem;
  position: relative;
  border-radius: 5px 5px 5px 5px;
  max-width: calc(100% - 78px);
  width: 85%;
  border-width: 2px 0px 2px 2px;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
  left: 80px;
  margin-top: 40px;

  &::after {
    content: ' ';
    right: calc(100% - 0px);
    top: 3rem;
    border-style: solid;
    border-color: transparent rgb(32, 32, 36) transparent transparent;
    border-image: initial;
    height: 0px;
    width: 0px;
    position: absolute;
    border-width: 10px;
    margin-top: -10px;
  }

  .avatar {
    position: absolute;
    top: 20px;
    left: -75px;
    z-index: auto;

    img {
      width: 60px;
      height: 60px;
      padding: 2px;
      border: 2px solid rgb(225, 225, 230);
      border-radius: 100%;
      background-position: center center;
      background-size: cover;
      background-clip: content-box;
    }
  }

  @media ${device.mobile} {
    left: 0px;
    width: 100%;
    max-width: 100%;
    padding: 1rem;

    &::after {
      content: ' ';
      right: calc(100% - 0px);
      top: 3rem;
      border-style: solid;
      border-color: transparent transparent transparent;
      border-image: initial;
      height: 0px;
      width: 0px;
      position: absolute;
      border-width: 10px;
      margin-top: -10px;
    }

    .avatar {
      display: none;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;

    header {
      display: flex;
      flex-direction: column;

      span {
        color: rgb(168, 168, 179);
        font-size: 16px;
        font-weight: 500;
      }
      time {
        font-size: 14px;
        font-weight: normal;
        color: rgb(115, 115, 128);
      }
    }

    h5 {
      margin-top: 1.3rem;
    }

    .divider {
      background-color: rgb(50, 50, 56);
      height: 1px;
      width: 100%;
      margin: 1rem 0px;
    }

    text {
      color: rgb(168, 168, 179);
    }

    .line-break {
      white-space: pre-wrap !important;
    }

    footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      span {
        background: #ea333a;
        padding: 6px 14px;
        border-radius: 15px;
        margin-right: 10px;
        color: #fff;
        font-size: 14px;
      }
    }
  }
`;

export const Messages = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 40px;

  .no-answers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
`;

export const Message = styled.div`
  display: flex;
  margin-bottom: 20px;

  background-color: rgb(32, 32, 36);
  padding: 1.4rem 2.4rem;
  position: relative;
  border-radius: 5px 5px 5px 5px;
  max-width: calc(100% - 78px);
  width: 85%;
  border-width: 2px 0px 2px 2px;
  border-style: solid;
  border-color: transparent;
  border-image: initial;
  left: 80px;

  &::after {
    content: ' ';
    right: calc(100% - 0px);
    top: 3rem;
    border-style: solid;
    border-color: transparent rgb(32, 32, 36) transparent transparent;
    border-image: initial;
    height: 0px;
    width: 0px;
    position: absolute;
    border-width: 10px;
    margin-top: -10px;
  }

  .avatar {
    position: absolute;
    top: 20px;
    left: -75px;
    z-index: auto;

    img {
      width: 60px;
      height: 60px;
      padding: 2px;
      border: 2px solid rgb(225, 225, 230);
      border-radius: 100%;
      background-position: center center;
      background-size: cover;
      background-clip: content-box;
    }
  }

  @media ${device.mobile} {
    left: 0px;
    width: 100%;
    max-width: 100%;
    padding: 1rem;

    &::after {
      content: ' ';
      right: calc(100% - 0px);
      top: 3rem;
      border-style: solid;
      border-color: transparent transparent transparent;
      border-image: initial;
      height: 0px;
      width: 0px;
      position: absolute;
      border-width: 10px;
      margin-top: -10px;
    }

    .avatar {
      display: none;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;

    header {
      display: flex;
      flex-direction: column;

      span {
        color: rgb(168, 168, 179);
        font-size: 16px;
        font-weight: 500;
      }
      time {
        font-size: 14px;
        font-weight: normal;
        color: rgb(115, 115, 128);
      }
    }

    h5 {
      margin-top: 1.3rem;
    }

    .divider {
      background-color: rgb(50, 50, 56);
      height: 1px;
      width: 100%;
      margin: 1rem 0px;
    }

    text {
      color: rgb(168, 168, 179);
    }

    .line-break {
      white-space: pre-wrap !important;
    }

    footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      span {
        background: #ea333a;
        padding: 6px 14px;
        border-radius: 15px;
        margin-right: 10px;
        color: #fff;
        font-size: 14px;
      }
    }
  }
`;
