import React from 'react';

import { Container } from './styles';

interface ProgressBarProps {
  percentage: number;
}

const ProgressBar: React.FC<ProgressBarProps> = percentage => {
  return (
    <Container percentage={percentage.percentage}>
      <div />
    </Container>
  );
};

export default ProgressBar;
