import React, { useEffect, useState, useCallback } from 'react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import { FiLoader } from 'react-icons/fi';

import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import { i18n } from '../../translate/i18n';

import Menu from '../../components/Menu';
import Footer from '../../components/Footer';
import NormalButton from '../../components/NormalButton';
import HeaderInfo from '../../components/HeaderInfo';

import { Container } from './styles';
import { useVideos } from '../../hooks/Videos';

interface ExamScore {
  id: number;
  exam_id: number;
  score: string;
}
interface ExamData {
  id: number;
  title: string;
  description: string;
  recovery: boolean;
  ExamScores: ExamScore[];
}
interface ModuleIdParams {
  module_id: string;
  course_id: string;
}

const Exam: React.FC = () => {
  const [exam, setExam] = useState<ExamData[]>([]);
  const [modulePercentage, setModulePercentage] = useState();
  const [loadingExam, setLoadingExam] = useState<boolean>(false);
  const [haveVideos, setHaveVideos] = useState<boolean>(true);

  const { student } = useAuth();
  const { videos, getVideos } = useVideos();
  const { addToast } = useToast();
  const { params } = useRouteMatch<ModuleIdParams>();
  const history = useHistory();

  const course_info = {
    course_id: params.course_id,
    module_id: params.module_id,
  };

  const getExam = useCallback(async () => {
    try {
      setLoadingExam(true);
      await api
        .get(`/exam/${params.course_id}/${params.module_id}/${student.id}`)
        .then(response => {
          setExam(response.data.exams);
        });

      await api
        .get(
          `/percentage_completed_module/${params.module_id}/student_id/${student.id}`,
        )
        .then(response => {
          setModulePercentage(response.data);
        });

      setLoadingExam(false);
    } catch (err) {
      setLoadingExam(true);

      addToast({
        type: 'error',
        title: i18n.t('errors.error'),
      });
      setLoadingExam(false);

      history.push(`/dashboard`);
    }
  }, [addToast, params.course_id, params.module_id, student.id, history]);

  useEffect(() => {
    getExam();
    getVideos(params.course_id, params.module_id);
  }, [getExam, getVideos, params.course_id, params.module_id]);

  useEffect(() => {
    if (!videos.length) {
      setHaveVideos(false);
    }
  }, [videos]);

  return (
    <>
      <Menu course_info={course_info} />
      <HeaderInfo course_info={course_info} />

      <Container>
        {loadingExam ? (
          <>
            <FiLoader size={20} />
            <span>{i18n.t('loading')}...</span>
          </>
        ) : (
          <>
            {!exam ? (
              <h4> Este módulo não possuí avaliação! </h4>
            ) : (
              <>
                {exam.map(e => (
                  <>
                    <div className="exams" key={e.id}>
                      <div>
                        <p className="title">{e.title}</p>
                        <p>{e.description}</p>

                        <p className="nota">
                          {e.ExamScores.length ? (
                            <>
                              Nota:
                              <span>{e.ExamScores[0]?.score}</span>
                            </>
                          ) : (
                            <>{i18n.t('exam.status')}</>
                          )}
                        </p>

                        {e.ExamScores.length ? (
                          <NormalButton
                            type="button"
                            disabled
                            className="btn btn-sm"
                          >
                            {i18n.t('exam.finish')}
                          </NormalButton>
                        ) : (
                          <>
                            {modulePercentage! < 100 && haveVideos ? (
                              <>
                                <span>{i18n.t('exam.block')}</span>
                                <Link
                                  to={`/course/${params.course_id}/module/${params.module_id}/`}
                                >
                                  <NormalButton
                                    type="button"
                                    className="btn btn-sm"
                                  >
                                    {i18n.t('exam.button_videos')}
                                  </NormalButton>
                                </Link>
                              </>
                            ) : (
                              <Link
                                to={`/exam/questions/${e.id}/${params.course_id}/${params.module_id}`}
                              >
                                <NormalButton
                                  type="button"
                                  className="btn btn-sm"
                                >
                                  {i18n.t('exam.start')}
                                </NormalButton>
                              </Link>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
          </>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default Exam;
