import styled from 'styled-components';

import {
  Container as ContainerStyled,
  Button as ButtonStyled,
} from 'react-bootstrap';

export const Container = styled(ContainerStyled)`
  svg {
    animation: spin 2s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .score {
    background: #6c757d;
    padding: 5px;
    width: 280px;
    border-radius: 10px;
    color: white;
    margin: 1rem 0;

    font-size: 14px;
    span {
      margin-left: 5px;
    }
  }

  .final-message {
    margin-top: 10px;

    p {
      margin-bottom: 0.3rem;
    }
  }
`;

export const Button = styled(ButtonStyled)`
  margin-top: 10px;

  background: #009ee2;
`;
