import styled from 'styled-components';
import {
  Container as ContainerStyled,
  Modal as ModalStyled,
} from 'react-bootstrap';

export const Modal = styled(ModalStyled)`
  color: #333;

  .modal-dialog {
    max-width: 800px;
  }
`;

export const Container = styled(ContainerStyled)`
  @media (min-width: 768px) {
    max-width: 1280px !important;
  }

  margin-bottom: 20px;

  h5 {
    text-align: center;
  }

  svg {
    text-align: center;
    margin: 0 8px;
    animation: spin 2s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .modal-content {
    background: black !important;
  }
`;

export const VideosContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  background: #28262e;
  border-radius: 5px;
  padding: 20px;

  .react-vimeo {
    margin-right: 25px;
    min-width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h6 {
      span {
        font-size: 1rem;
        margin-left: 7px;
      }
    }

    @media (max-width: 576px) {
      iframe {
        width: 100%;
        height: 180px;
      }

      width: 100%;
      margin: 0 0 20px 0;
    }
  }

  .video-list {
    background: #1d1b21;
    padding: 15px;
    width: 42%;
    border-radius: 5px;

    @media (max-width: 576px) {
      width: 100%;
    }

    ol {
      div {
        display: flex;
        flex-direction: column;
        min-width: 100%;
        margin-bottom: 20px;
        border-bottom: solid 1px #ccc;

        h5 {
          text-align: left;
          margin-bottom: 3px;
        }

        span {
          font-weight: 200;
          color: #ccc;
        }
      }

      .video-titles {
        margin: 0;
        border: 0;

        button {
          background: transparent;
          border: 0;
          padding: 0;
          color: #ccc;

          li {
            margin: 4px;
            text-align: left;
            font-weight: 200;
            font-size: 14px;
            list-style: none;

            svg {
              text-align: center;
              margin-left: 8px;
              animation: none;
              color: #2ecc71;
            }
          }

          &:hover {
            color: #009ee2;
            transition: 0.2s;
          }

          &:focus {
            color: #009ee2;
            transition: 0.2s;
            outline: 0;
          }
        }
      }
    }
  }
`;
