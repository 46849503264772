import styled from 'styled-components';
import signInBackgroundImg from '../../assets/signin-background.jpg';

export const Body = styled.div`
  height: 100vh;
  background: #312e38;
  color: white;

  display: flex;
  align-items: stretch;
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBackgroundImg}) no-repeat center;
  background-size: cover;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 680px;

  img {
    width: 18%;
  }

  p {
    margin-top: 10px;
    text-align: center;
  }

  h4 {
    margin-top: 10px;
  }

  form {
    h1 {
      font-size: 25px;
      text-align: center;
    }

    margin: 50px 0;
    width: 340px;

    h1 {
      margin-bottom: 24px;
    }

    button {
      width: 100%;

      svg {
        animation: spin 2s linear infinite;
      }

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
`;
